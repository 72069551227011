<template>
  <BaseDialog
    title="編輯刷卡機 ezAIO 串接設定"
    :btn1-loading="loading"
    width="610px"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top" testName="ezAIOSetting">
        <BaseElFormItem label="啟用刷卡機串接設定">
          <BaseElSwitch
            v-model="formData.enable"
            testName="formData_enable"
            active-text="開啟"
            inactive-text="關閉"
          />
        </BaseElFormItem>
        <BaseElFormItem label="AIO_MerchantID" prop="merchantId">
          <BaseElInput v-if="!formData.enable" v-model="formData.merchantId" testName="formData_merchantId" />
          <span v-else>{{ formData.merchantId || '-' }}</span>
        </BaseElFormItem>

        <BaseElFormItem label="NewebPay_MerchantID" prop="newebpayMerchantId">
          <BaseElInput v-if="!formData.enable" v-model="formData.newebpayMerchantId" testName="formData_newebpayMerchantId" />
          <span v-else>{{ formData.newebpayMerchantId || '-' }}</span>
        </BaseElFormItem>

        <!-- <BaseElFormItem label="HashKey">
          <BaseElInput v-if="!formData.enable" v-model="formData.hashKey" testName="formData_hashKey" />
          <span v-else>{{ formData.hashKey }}</span>
        </BaseElFormItem>
        <BaseElFormItem label="HashIV">
          <BaseElInput v-if="!formData.enable" v-model="formData.hashIV" testName="formData_hashIv" />
          <span v-else>{{ formData.hashIV }}</span>
        </BaseElFormItem> -->
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onBeforeMount } from 'vue'
import { UpdateSingleSalesPaymentConfig } from '@/api/sales'
import notifyMessage from '@/config/notifyMessage'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'EditEZAIOSettingModal',
  components: {
    BaseDialog,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, loading } = useBaseForm()
    const { shopId } = useShop()

    const formRules = computed(() => {
      const rules = {
        merchantId: [noEmptyRules()],
        newebpayMerchantId: [noEmptyRules()],
      }
      return rules
    })

    initFormData({
      enable: false,
      merchantId: null,
      newebpayMerchantId: null,
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const [, err] = await UpdateSingleSalesPaymentConfig({
        shopId: shopId.value,
        enable: formData.enable,
        type: 'ezAIOTrust',
        config: {
          ezAIOTrustConfig: {
            merchantID: formData.merchantId,
            newebpayMerchantID: formData.newebpayMerchantId,
          },
        },
        // offlinePaymentClientId,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    onBeforeMount(() => {
      const ezAIOConfig = get(props.configData, 'config.ezAIOTrustConfig')
      formData.enable = get(props.configData, 'enable')
      formData.merchantId = ezAIOConfig.merchantID
      formData.newebpayMerchantId = ezAIOConfig.newebpayMerchantID
    })

    return { formRef, formData, formRules, loading, onConfirm }
  },
})

</script>

<style lang="postcss" scoped>
</style>
